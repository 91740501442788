import {createClient} from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const client = createClient({
    projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
    dataset: 'production',
    useCdn: true,
    apiVersion:'2023-05-03',
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => {
    return builder.image(source);
};